#cc-user-wizard {
    #cc-user-wizard-step-1 {
    
    }

    #cc-user-wizard-step-1a-client,
    #cc-user-wizard-step-2,
    #cc-user-wizard-step-3-client,
    #cc-user-wizard-step-3-advisor,
    #cc-user-wizard-step-4,
    #cc-user-wizard-steps-complete {
        display: none;
    }

    #permissions-tree {
        margin-top: 1em;
        margin-bottom: 2em;

        .oi {
            margin-top: -2px;
        }
    }

    .cc-feather-class-replace {
        svg {
            width: 1em;
            height: 1em;
            display: block;
            top: 50%;
            left: 50%;
            position: relative;
            transform: translate(-50%, -50%);
        }
    }

    #verify-permissions-tree {
        margin: 1em 0;
        cursor: default!important;

        &:focus {
            outline: none!important;
        }

        .jstree-wholerow-ul,
        .jstree-wholerow,
        .jstree-ocl,
        .jstree-default-large,
        .jstree-anchor {
            cursor: default!important;
        }
    }
}

#cc-project-wizard {
    #cc-project-wizard-step-1 {
    
    }

    #cc-project-wizard-step-2,
    #cc-project-wizard-step-3,
    #cc-project-wizard-step-4,
    #cc-project-wizard-steps-complete {
        display: none;
    }
}

#cc-organization-wizard {
    #cc-organization-wizard-step-1 {
    
    }

    #cc-organization-wizard-step-2,
    #cc-organization-wizard-step-3,
    #cc-organization-wizard-steps-complete {
        display: none;
    }
}

/* 
TODO:

This stuff should be inside #verify-permissions-tree but the selectors are
being dumb fucking pieces of shit and I've unironically spent an hour on this, 
so fuck it, we'll just throw it up at the top level and force an override. 
Note that this breaks the default orange hover outline for checkboxes.

At some point this should probably be fixed.
*/
.jstree-default-large .jstree-checkbox:hover {
    background-position: -160px 0;
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover, 
.jstree-default-large .jstree-checked>.jstree-checkbox:hover {
    background-position: -224px 0;
}